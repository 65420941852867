import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { useLanguage } from './LanguageContext';



function App() {
  const { language } = useLanguage(); // Usar el idioma del contexto
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Obtener la sesión inicial
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    // Escuchar cambios en el estado de autenticación
    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    // Limpiar suscripción
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  // Lazy imports basados en el idioma
  const Landing = lazy(() =>
    language === 'es'
      ? import('./components/Landing')
      : import('./components-en/Landing')
  );
  const Dashboard = lazy(() =>
    language === 'es'
      ? import('./components/Dashboard')
      : import('./components-en/Dashboard')
  );
  const Login = lazy(() =>
    language === 'es'
      ? import('./components/Login')
      : import('./components-en/Login')
  );
  const Register = lazy(() =>
    language === 'es'
      ? import('./components/Register')
      : import('./components-en/Register')
  );
  const Confianza = lazy(() =>
    language === 'es'
      ? import('./components/Confianza')
      : import('./components-en/Confianza')
  );
  const Terms = lazy(() =>
    language === 'es'
      ? import('./components/Terms')
      : import('./components-en/Terms')
  );


  return (
    <div className="w-full h-screen">
      <Suspense fallback={<div>Cargando...</div>}>
        <BrowserRouter>
          {/* Navbar */}        
          <Routes>
            {/* Rutas públicas */}
            <Route path="/" element={<Landing />} />
            <Route path="/Confianza" element={<Confianza />} />
            <Route path="/terms" element={<Terms />} />
        
           
            <Route
              path="/Login"
              element={!session ? <Login /> : <Navigate to="/Dashboard" />}
            />
            <Route
              path="/Register"
              element={!session ? <Register /> : <Navigate to="/Dashboard" />}
            />
            {/* Rutas protegidas */}
            <Route
              path="/Dashboard"
              element={session ? <Dashboard session={session} /> : <Navigate to="/Login" />}
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
